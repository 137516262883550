import { Technology } from '@ncs-frontend-monorepo/availability';
import {
  AccessType,
  ExpansionAccessType,
  FTTHPresalesInformation,
} from '@ncs-frontend-monorepo/availability';
import { Product } from '@ncs-frontend-monorepo/order';

export const existsPlanWithinBandwidthRange = (
  plans: Product[],
  ftthPresalesInformation: FTTHPresalesInformation,
) =>
  ftthPresalesInformation &&
  ftthPresalesInformation.isOffered &&
  plans.some(
    (plan) =>
      plan.internet.download >= ftthPresalesInformation.minAvailableDownload &&
      plan.internet.download <= ftthPresalesInformation.maxAvailableDownload,
  );

export const expansionAccessTypeToTechnology = (
  expansionAccessType: ExpansionAccessType,
): Technology => {
  switch (expansionAccessType) {
    case ExpansionAccessType.CABLE:
      return Technology.CABLE;
    case ExpansionAccessType.G_FAST:
      return Technology.GFAST;
    case ExpansionAccessType.VDSL_2_FTTB:
      return Technology.VDSL2_FTTB;
    default:
      return Technology.FTTH;
  }
};

// send mergedAccessType to BE - BE need this part
// if UNKNOWN mergedAccessType send FTTH as technology
export const mergedAccessTypeToTechnology = (
  mergedAccessType: AccessType,
): Technology => {
  switch (mergedAccessType) {
    case AccessType.CABLE:
      return Technology.CABLE;
    case AccessType.G_FAST:
      return Technology.GFAST;
    case AccessType.VDSL_2_FTTB:
      return Technology.VDSL2_FTTB;
    case AccessType.FTTH_BSA_L2:
      return Technology.FTTH_BSA_L2;
    case AccessType.FTTH_BSA_L2_DG:
      return Technology.FTTH_BSA_L2_DG;
    default:
      return Technology.FTTH;
  }
};
