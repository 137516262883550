import { useRouter } from 'next/router';
import { mergedAccessTypeToTechnology } from '../utils/ftthPreMarketing';
import {
  DeviceCategory,
  DeviceOwnership,
  getProducts,
  ProductId,
  useCheckoutSelectionStore,
  useNotConnected,
  useReset,
} from '@ncs-frontend-monorepo/order';
import {
  checkAvailability,
  FetchAddressReponse,
  useAvailability,
} from '@ncs-frontend-monorepo/availability';

export const useFTTHCheckout = () => {
  const router = useRouter();
  const { resetBasket } = useReset();
  const { basket, updateBasket, updateAvailableProductOptions } =
    useNotConnected();
  const { address, availability } = useAvailability();
  const { update } = useCheckoutSelectionStore();

  const goToFTTHCheckout = async (
    templateId: ProductId,
    keepOptions = false,
    recheckAddress = true,
  ): Promise<boolean> => {
    let checkedAddress: FetchAddressReponse;
    if (recheckAddress) {
      const response = await checkAvailability({ ...address });
      checkedAddress = response?.address;
    } else {
      const { address: addressResult } = await checkAvailability({
        street: address.street,
        houseNumber: address.houseNumber,
        zipCode: address.zipCode,
        city: address.city,
      });
      checkedAddress = addressResult;
    }

    if (!checkedAddress) {
      return false;
    }

    const productsResult = await getProducts({
      templateId,
      withoutRuntime: false,
      maxDownload: availability.ftthPresalesInformation?.maxAvailableDownload,
      technology: mergedAccessTypeToTechnology(
        availability.ftthPresalesInformation.mergedAccessType,
      ),
    });

    if (!productsResult) {
      return false;
    }

    const ftthDefaultInstallationService = keepOptions
      ? basket?.installationService
      : null;

    const defaultOwnerShip = keepOptions
      ? basket?.device?.ownership || DeviceOwnership.RENT
      : DeviceOwnership.RENT;

    const ftthDefaultOntDevice =
      productsResult.ontDevices.find(
        (ontDevice) =>
          ontDevice.category === DeviceCategory.ONT &&
          ontDevice.ownership === DeviceOwnership.BUY,
      ) || null;

    const ftthDefaultDevice =
      productsResult.devices.find(
        (device) =>
          device.category === DeviceCategory.PREMIUM &&
          device.ownership === defaultOwnerShip,
      ) || null;

    resetBasket();

    updateBasket({
      id: productsResult.id,
      promotionId: productsResult.promotionId,
      internet: productsResult.internet,
      telephony: productsResult.telephony || null,
      fallback: productsResult.fallback,
      device: {
        id: ftthDefaultDevice.id,
        category: ftthDefaultDevice.category,
        ownership: ftthDefaultDevice.ownership,
      },
      ontDevice: {
        id: ftthDefaultOntDevice?.id,
        category: ftthDefaultOntDevice?.category,
        ownership: ftthDefaultOntDevice?.ownership,
      },
      technology: productsResult.technology,
      installationAddress: checkedAddress,
      installationService: ftthDefaultInstallationService,
      isWithoutRuntime: false,
      plannedAvailabilityDate: null,
      plannedAvailabilityDateDescription: null,
      tv: productsResult.tv,
      group: productsResult.group,
    });
    update({
      iadDevice: ftthDefaultDevice,
      ontDevice: ftthDefaultOntDevice,
      installationService: ftthDefaultInstallationService,
    });
    updateAvailableProductOptions({
      devices: productsResult.devices,
      ontDevices: productsResult.ontDevices,
      installationServices: productsResult.installationServices,
    });

    router.push(
      (process.env.NEXT_PUBLIC_SITE_PREFIX || '') + '/ftth/bestellung/optionen',
    );
    return true;
  };

  return {
    goToFTTHCheckout,
  };
};
